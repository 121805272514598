.mt {
  margin-top: 20px;
}

.mb {
  margin-bottom: 20px;
}

.mv {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ml {
  margin-left: 20px;
}

.mr {
  margin-right: 20px;
}

.mh {
  margin-left: 20px;
  margin-right: 20px;
}

.pt {
  padding-top: 20px;
}

.pb {
  padding-top: 20px;
}

.pv {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}

.ph {
  padding-left: 20px;
  padding-right: 20px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.hidden {
  display: none;
}

.bb {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}
