@import './fonts.css';
@import './antd.css';
@import './antdOverride.css';
@import './utils.css';

html,
body {
  padding: 0;
  margin: 0;
  color: #5e748a;
  background-color: white;
}

body.fullscreen {
  overflow: hidden;
  background-color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.not-visible {
  visibility: hidden;
}

ol {
  padding-inline-start: 0;
}

.ant-notification {
  z-index: 999999;
}
